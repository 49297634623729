
import { Component, Vue } from 'vue-property-decorator';
import { DefaultTableVersionModel } from '@/models/default_table_version';

@Component({
  components: {
  },
})
export default class DefaultTableVersionNewPage extends Vue {
  defaultTableVersion: DefaultTableVersionModel = new DefaultTableVersionModel();
  updating: boolean = false;

  public async updateVersion() {
    this.updating = true;
    await this.defaultTableVersion.create();
    this.$router.push('/default_table_versions');
    this.updating = false;
  }
}
