import { get, post, put } from '@/services/http';

export class DefaultTableVersionModel {
  public id: number;
  public name: string;
  public version: number;
  public partition: boolean;
  public createdAt?: string;
  public updatedAt?: string;

  constructor(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.version = data.version;
    this.partition = data.partition || false;
  }

  public mapData(data: any = {}) {
    this.id = data.id;
    this.name = data.name;
    this.version = data.version;
    this.partition = data.partition || false;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
  }

  public async create() {
    return await post('/default_table_versions', {
      name: this.name,
      version: this.version,
      partition: this.partition,
    });
  }

  public async update() {
    return await put(`/default_table_versions/${this.name}`, {
      version: this.version,
      partition: this.partition,
    });
  }

  public async fetch() {
    const res: any = await get(`/default_table_versions/${this.name}`);
    this.mapData(res.data);
  }
}
